/* content: Schedule */
#sectionSchedule {
  margin: 10px;
  background-color: white;
}
.pageSection .sectionSchedulesub {
  padding: 30px;
  width: 100%;
  height: 100%;
  background-color:white;
  overflow: auto;
}
#sectionSchedule h2 {
  text-transform: uppercase;
  font-size: 50px;
  font-weight: 600;
}
p#pSchedule {
  font-size: 25px;
  text-justify: distribute;
  text-align: justify;
}
@media only screen and (max-width: 850px) {
  .pageSection .sectionSchedulesub {padding: 0px;}
  #sectionSchedule h2 {font-size: 20px;}
  p#pSchedule {font-size: 18px;}
}
/* Timetable */
.cbTableStyle {
  float: left;
  margin-right: 15px;
}
#timeTableNotes {
  float: left;
  font-size: 16px;
}
@media only screen and (max-width: 850px) {
  #timeTableNotes {font-size: 18px;}
}