/* content: Skill */
#sectionSkill {
  margin: 10px;
  background-color: white;
}
.pageSection .sectionSkillsub {
  padding: 30px;
  width: 100%;
  height: 100%;
  background-color:white;
  overflow: auto;
}
.pageSection .sectionSkillsub p {
  font-size: 25px;
  text-justify: distribute;
  text-align: justify;
}
#sectionSkill h2 {
  text-transform: uppercase;
  font-size: 50px;
  font-weight: 600;
}
@media only screen and (max-width: 850px) {
  .pageSection .sectionSkillsub {padding: 0px;}
  #sectionSkill h2 {font-size: 20px;}
  #pSkill {font-size: 18px;}
  #pSkill2 {font-size: 18px;}
}
/* Bars */
.chartBarsHorizontal .chartBars .bars {
  flex-direction: column;
}
.chartBarsWrap {
  padding-left: 40px;
  margin: 0 30px 0 20px;
}
.chartBars {
  position: relative;
  max-width: 800px;
  height: 300px;
  margin: 50px auto 100px;
}
.chartBars .bars {
  display: flex;
  justify-content: space-around;
  border-left: 1px solid #bbb;
  border-bottom: 1px solid #bbb;
  width: 100%;
  height: 100%;
  padding: 0;
  padding: 0 1%;
  margin: 0;
}
.chartBars .bars li {
  display: inline-block;
  flex: 0 1 24%;
  height: 100%;
  margin: 0;
  text-align: center;
  position: relative;
  font-size: 16px;
}
.chartBars .bars li .bar {
  width: 100%;
  background: #49E;
  position: absolute;
  font-size: 1.5em;
  color: #fff;
  padding-top: 18px;
  bottom: 0;
  height: 0;
  overflow: hidden;
  font-weight: bold;
  outline: 2px solid transparent;
  transition: 1.5s height cubic-bezier(0.6, 0.4, 0.4, 1.1);
}
.chartBars .bars li .bar:after {
  content: '%';
  font-size: 22px !important;
  vertical-align: top;
  color: rgba(255, 255, 255, 0.8);
  margin-right: -8px;
}
.chartBars .bars li b {
  color: #eee;
  width: 100%;
  position: absolute;
  bottom: -2em;
  left: 0;
  text-align: center;
}
.chartBars .numbers {
  width: 50px;
  height: 100%;
  margin: 0;
  padding: 0;
  display: inline-block;
  position: absolute;
  left: -50px;
}
.chartBars .numbers li {
  text-align: right;
  padding-right: 1em;
  list-style: none;
  height: 59px;
  position: relative;
  font-size: 13px;
  bottom: 11px;
  right: -9px;
  color: #eee;
}
.chartBars .numbers li:after {
  content: "\00af";
  position: relative;
  right: -5px;
  font-size: 20px;
  top: 7px;
  color: rgba(255, 255, 255, 0.34);
}
.chartBars .numbers li:first-of-type {
  height: 63px;
  margin-top: -1px;
}
.chartBars1 .bar {
  border-top-right-radius: 30px;
}
.chartBars2 {
  max-width: 350px;
}
.chartBars2 .bars {
  padding: 0;
}
.chartBars2 .bars li {
  flex: 0 1 25%;
}
.chartBars2 .bars li .bar {
  box-shadow: 0 0 20px #222;
}
.chartBars3 {
  background: rgba(255, 255, 255, 0.1);
  border-top: 1px solid #666;
  border-right: 1px solid #666;
}
.chartBars3 .bars {
  border-color: #666;
  padding: 0;
}
.chartBars3 .bars li .bar {
  border-top-right-radius: 3px;
  border-top-left-radius: 3px;
  font-size: 22px !important;
}
.chartBars3 .bars li .bar:after {
  font-size: 0.6em !important;
}
.chartBarsWrap.chartBarsHorizontal {
  flex-direction: column;
  padding-left: 70px;
}
.chartBarsWrap.chartBarsHorizontal .bars b {
  color: black;
  width: 100%;
  position: absolute;
  bottom: initial;
  top: 1em;
  left: -102%;
  text-align: right;
}
.chartBarsWrap.chartBarsHorizontal .chartBars {
  height: 200px;
}
.chartBarsWrap.chartBarsHorizontal .chartBars .bars {
  border: 0;
}
.chartBarsWrap.chartBarsHorizontal .chartBars .bars .bar {
  width: 0;
  padding-top: 10px;
  height: 100% !important;
  text-align: right;
  font-size: 1.5em;
  transition: 2s width cubic-bezier(0.6, 0.4, 0.4, 1.1);
}
.chartBarsWrap.chartBarsHorizontal .chartBars .bars .bar:after {
  font-size: 0.6em !important;
  margin-right: 20px;
}
.bars li .bar.greenBar { background-color: #6B8E23; }
.bars li .bar.lightBlueBar { background-color: #49E; }
.bars li .bar.blueBar { background-color: rgb(8, 82, 241); }
.bars li .bar.blueGreyBar { background-color: #37474f; }
.bars li .bar.amber { background-color: #ffc400; }
.bars li .bar.orangeBar { background-color: orange; }
.bars li .bar.deepOrangeBar { background-color: #ff5722; }
.bars li .bar.purpleBar { background-color: #6e46af; }
.bars li .bar.greyBar { background-color: #969696; }
.bars li .bar.redBar { background-color: #dd2c00; }
.bars li .bar.pinkBar { background-color: #ef5e5e; }
@media only screen and (max-width: 850px) {
  .chartBars {margin: 5px auto 10px;}
  .chartBars .bars {height: 80%;}
  .chartBars .bars li {
    height: 80%;
    font-size: 12.5px;
  }
}
/* Pie Charts */
.skill-charts-container {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  width: 100%;
}
.skill-chart {
  flex: 1 1 50%;
}
@media (max-width: 850px) {
  .skill-chart {
    flex: 1 1 100%;
  }
}