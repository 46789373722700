/* ---------- Bottom Scroll Progress Bar ---------- */
#progressbar {
  position:fixed;
  top:0%;
  width: 100%;
  background-color: rgb(129, 199, 129);
  height: 16px;
  z-index: 99;
}
#progressbar::-webkit-progress-value {
  background-image:
    -webkit-linear-gradient(-45deg, transparent 33%, rgba(0, 0, 0, .1) 33%, rgba(0,0, 0, .1) 66%, transparent 66%),
    -webkit-linear-gradient(top, rgba(255, 255, 255, .25), rgba(0, 0, 0, .25)),
    -webkit-linear-gradient(left, #09c, #f44);
  border-radius: 2px; 
  background-size: 35px 20px, 100% 100%, 100% 100%;
}
@media only screen and (max-width: 850px) {
  #progressbar {
    top: 0%;
    height: 16px;
  }
}