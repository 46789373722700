/* Code Block */
.codeblock-container {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  
}
.codeblock {
  display: inline-block;
  margin: 10px;
  width: 45%;
  background-color: rgb(60, 60, 60);
  padding: 5px;
  font-size: 14px;
  border-radius: 5px;
  overflow: auto;
}