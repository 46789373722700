/* content: Contact */
#sectionContact {
  margin: 10px;
  background-color: white;
}
.pageSection .sectionContactsub {
  padding: 30px;
  width: 100%;
  height: 100%;
  background-color:white;
  overflow: auto;
}
#sectionContact h2 {
  text-transform: uppercase;
  font-size: 50px;
  font-weight: 600;
}
p#pContact {
  font-size: 25px;
  text-justify: distribute;
  text-align: justify;
  display: block;
  border: 2px solid black;
  padding: 25px;
  border-radius: 10px;
}
@media only screen and (max-width: 850px) {
  .pageSection .sectionContactsub {padding: 0px;}
  #sectionContact h2 {font-size: 20px;}
  p#pContact {font-size: 18px;}
}