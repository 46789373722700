/* ---------- Footer ---------- */
.footer {
  position: relative;
  display: inline-block; 
  width: 100%;   
  height: auto;
  margin-top: 60px;
  padding: 5px;
  text-align: center;
  font-size: 12px;
  background-color: rgb(235, 235, 235);
  clear: both;
}
.footer a {
  color: rgb(80, 80, 80);
  font-weight: bold;
  text-decoration: none;
}