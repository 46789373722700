/* ---------- Sticky Navbar ---------- */
#myStickyNavbar li a {
  cursor: pointer;
  padding-left: 10px;
  padding-right: 10px;
  font-size: 22px;
  text-transform: uppercase;
}
#myStickyNavbar li .nav-link:hover {
  background-color: #333;
  color: #fff;
}
.subsIcon {
  width: 100%;
  max-width: 40px;
  height: auto;
  margin: 5px;
}
/* collapse set to side bar */
@media only screen and (max-width: 1000px) {
  .navbar-collapse.collapsing .navbar-nav {
    display: block;
    position: fixed;
    top: 0;
    bottom: 0;
    left: -15%;
    transition: all 0.2s ease;
    z-index: 999;
  }
  .navbar-collapse.show .navbar-nav {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    flex-direction: column;
    height: auto;
    width: 50%;
    transition: left 0.35s ease;
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
    background-color: rgb(255, 255, 255);
    font-size: 12px;
  }
  #myStickyNavbar li a {
    font-size: 16px;
  }
}