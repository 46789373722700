.baseDiv { height: 100%; }

/* ---------- Custom Scrollbar (not supported in Firefox or IE/Edge) ---------- */
/* width */
::-webkit-scrollbar { width: 7px; }
/* Track */
::-webkit-scrollbar-track { background: #f1f1f1; }
/* Handle */
::-webkit-scrollbar-thumb { background: #888; }
/* Handle on hover */
::-webkit-scrollbar-thumb:hover { background: #555; }
@media only screen and (max-width: 850px) { ::-webkit-scrollbar {display: none;} }
