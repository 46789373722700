/* ---------- General Layout ---------- */
* { box-sizing: border-box; }

.bodyDiv {
  /* Fading animation */
  -webkit-animation-name: fade;
  -webkit-animation-duration: 1.5s;
  animation-name: fade;
  animation-duration: 1.5s;

  background-color:white;
  font-family: 'Open Sans', 'Roboto', Arial, Helvetica, sans-serif;
  margin: 0;
}
@-webkit-keyframes fade {
  from {opacity: .4} 
  to {opacity: 1}
}
@keyframes fade {
  from {opacity: .4} 
  to {opacity: 1}
}
@media only screen and (max-width: 850px) {
  .prev, .next {font-size: 9px;}
  .text {bottom: 5px;}
  .dot {
    width: 5px;
    height: 5px;
  }
}

/* ---------- Main Content Container ---------- */
.content-container {
  height: 100%;
  width: 60%;
  margin-left: auto;
  margin-right: auto;
}
@media only screen and (max-width: 850px) {
  .content-container {width: 95%;}
}
/* content load animation */
.slideInLeft {
  -webkit-animation-name: slideInLeft;
  -webkit-animation-duration: 0.8s;
  animation-name: slideInLeft;
  animation-duration: 0.8s;
}
@-webkit-keyframes slideInLeft {
  0% { 
    transform: translate(20px);
    opacity: .4;
  }
  100% { 
    transform: translate(0px); 
    opacity: 1;
  }
}
@keyframes slideInLeft {
  0% { 
    transform: translate(20px);
    opacity: .4;
  }
  100% { 
    transform: translate(0px); 
    opacity: 1;
  }
}
.slideInRight {
  -webkit-animation-name: slideInRight;
  -webkit-animation-duration: 0.8s;
  animation-name: slideInRight;
  animation-duration: 0.8s;
}

@-webkit-keyframes slideInRight {
  0% { 
    transform: translateX(-50px);
    opacity: 0.4;
  }
  100% { 
    transform: translateX(0px);
    opacity: 1;
  }
}

@keyframes slideInRight {
  0% { 
    transform: translateX(-50px);
    opacity: 0.4;
  }
  100% { 
    transform: translateX(0px);
    opacity: 1;
  }
}
.slideInBottom {
  -webkit-animation-name: slideInBottom;
  -webkit-animation-duration: 0.8s;
  animation-name: slideInBottom;
  animation-duration: 0.8s;
}
@-webkit-keyframes slideInBottom {
  0% { 
    transform: translate(0px,30px);
    opacity: .4;
  }
  100% { 
    transform: translate(0px,0px); 
    opacity: 1;
  }
}
@keyframes slideInBottom {
  0% { 
    transform: translate(0px,25px);
    opacity: .4;
  }
  100% { 
    transform: translate(0px,0px); 
    opacity: 1;
  }
}
.hidden {
  opacity: 0;
}
/* button wrapper */
.buttonWrapper {
  text-align: center;
  margin: 10px;
}
