/* content: Hobby */
#sectionHobby {
  margin: 10px;
  background-color: white;
}
.pageSection .sectionHobbysub {
  padding: 30px;
  width: 100%;
  height: 100%;
  background-color:white;
  overflow: auto;
}
#sectionHobby h2 {
  text-transform: uppercase;
  font-size: 50px;
  font-weight: 600;
}
p#pHobby {
  font-size: 25px;
  text-justify: distribute;
  text-align: justify;
}
@media only screen and (max-width: 850px) {
  .pageSection .sectionHobbysub {padding: 0px;}
  #sectionHobby h2 {font-size: 20px;}
  p#pHobby {font-size: 18px;}
}
/* Hobby (Slide in Overlay) Section */
.hobbyAll {
  width: 100%;
  text-align: center;
}
.hobbyGamingIconWrapper, .hobbySportIconWrapper, .hobbyMovieIconWrapper, .hobbyMusicIconWrapper, .hobbyWrittingIconWrapper, .hobbyCodingIconWrapper {
  position: relative;
  width: 20%;
  display: inline-block;
  margin: 35px;
}
@media only screen and (max-width: 1200px) {
  .hobbyGamingIconWrapper, .hobbySportIconWrapper, .hobbyMovieIconWrapper, .hobbyMusicIconWrapper, .hobbyWrittingIconWrapper, .hobbyCodingIconWrapper {
    width: 50%;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
}
.hobbyIcon {
  display: block;
  width: 100%;
  height: auto;
  border: 2px solid black;
}
.hobbyText {
  color: white;
  font-size: 0.9vw;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  white-space: nowrap;
}
@media only screen and (max-width: 1200px) {
  .hobbyText {font-size: 20px;}
}
/* Slide in Overlay from the Left */
.overlayleftslide {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #008CBA;
  overflow: hidden;
  width: 0;
  height: 100%;
  transition: .5s ease;
}
.hobbyGamingIconWrapper:hover .overlayleftslide {
  width: 100%;
}
/* Slide in Overlay from the Top */
.overlaytopslide {
  position: absolute;
  bottom: 100%;
  left: 0;
  right: 0;
  background-color: rgb(186, 43, 0);
  overflow: hidden;
  width: 100%;
  height:0;
  transition: .5s ease;
} 
.hobbySportIconWrapper:hover .overlaytopslide {
  bottom: 0;
  height: 100%;
}
/* Slide in Overlay from the Right */
.overlayrightslide {
  position: absolute;
  bottom: 0;
  left: 100%;
  right: 0;
  background-color: rgb(149, 0, 186);
  overflow: hidden;
  width: 0;
  height: 100%;
  transition: .5s ease;
}
.hobbyMovieIconWrapper:hover .overlayrightslide {
  width: 100%;
  left: 0;
}
/* Slide in Overlay from the Bottom */
.overlaybottomslide {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgb(155, 158, 0);
  overflow: hidden;
  width: 100%;
  height: 0;
  transition: .5s ease;
}
.hobbyMusicIconWrapper:hover .overlaybottomslide {
  height: 100%;
}
/* Slide in Overlay Zoom */
.overlayzoom {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgb(29, 135, 143);
  overflow: hidden;
  width: 100%;
  height: 100%;
  -webkit-transform: scale(0);
  -ms-transform: scale(0);
  transform: scale(0);
  -webkit-transition: .3s ease;
  transition: .3s ease;
}
.hobbyWrittingIconWrapper:hover .overlayzoom {
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
}
/* Slide in Overlay Fade */
.overlayfade {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  transition: .5s ease;
  background-color: rgb(100, 107, 109);
}
.hobbyCodingIconWrapper:hover .overlayfade {
  opacity: 1;
}