/* ---------- Study ---------- */
/* contents */
#study-contents {
  line-height: 1.6;
}
#cmdline {
  display: block;
  overflow: auto;
  margin: 10px auto 10px auto;
  padding: 10px;
  text-align: left;
  background-color: rgb(80, 80, 80);
  color: white;
  width: 100%;
  border-radius: 3px;
  font-family: 'Courier New', Courier, monospace;
  font-size: 16px;
}
.contents-questions {
  border-collapse: collapse;
  width: 100%;
  font-size: 16px;
  margin: 10px auto 10px auto;
}
.contents-questions tr, .contents-questions th, .contents-questions td {
  border: 1px solid black;
  width: auto;
  padding: 5px;
  text-align: left;
}
/* image */
.imageBox {
  display: block;
  width: 100%;
  height: auto;
  margin: 20px 0px 20px 0px;
}