#pquote {
  font-size: 1.5vw;
  text-justify: distribute;
  text-align: justify;
  display: inline-block;
  border-left: 10px solid rgb(0, 0, 0);
  background-color: rgb(255, 255, 255);
  padding-left: 40px;
}
@media only screen and (max-width: 850px) {
  p#pquote {font-size: 3.5vw;}
}