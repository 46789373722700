/* ---------- List of Articles ---------- */
#articles-info {
  font-family: 'Open Sans', 'Noto Sans TC', Arial, Helvetica, sans-serif;
}
.articlesLink {
  font-size: 20px;
  text-decoration: none;
  color: black;
  font-family: 'Open Sans', 'Noto Sans TC', Arial, Helvetica, sans-serif;
}
.articlesLink:hover {
  color: blue;
}
p.first {
  font-size: 20px;
  text-indent: 2em;
  font-family: 'Open Sans', 'Noto Sans TC', Arial, Helvetica, sans-serif;
  text-align: justify;
}
/* ---------- List of Piano ---------- */
.pianoWrapper {
  width: 100%;
}
.pianoInline {
  display: inline-block;
  width: 50%;
  height: 50%;
  padding: 10px;
}
@media only screen and (max-width: 1000px) {
  .pianoInline {
    width: 100%;
    padding: 0px;
  }
}
