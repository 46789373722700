/* Slideshow container */
.slideshow-container {
  width: 100%;
  position: relative;
  margin: auto;
}
/* Caption text */
.text {
  background-color: black;
  color: #f2f2f2;
  font-size: 3vw;
  padding: 8px 12px;
  position: absolute;
  bottom: 20px;
  opacity: 0.7;
  width: auto;
  text-align: center;
  display: block;
}