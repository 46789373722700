/* ---------- Game, Web App, List of Articles ---------- */
.step-container {
  display: block;
  margin: 10px;
  padding: 10px;
  overflow: auto;
}
.step-container-body {
  display: block;
  width: 95%;
  position: relative;
  margin-left: auto;
  margin-right: auto;
  margin-top: 5px;
  margin-bottom: 5px;
  padding: 10px;
  font-size: 20px;
  text-justify: distribute;
  text-align: justify;
}
/* Table */
#webtable {
  border-collapse: collapse;
  width: 100%;
}
#webtable td, th {
  border: 1px solid rgb(179, 179, 179);
  text-align: left;
  padding: 8px;
}