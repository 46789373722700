/* content: About Me */
#sectionAboutMe {
  margin: 10px;
  background-color: white;
  width: 100%;
  overflow: auto;
  display: table;
}
#sectionAboutMe-row {
  display: table-row;
}
#sectionAboutMeRowChild {
  padding: 30px;
  width: 90%;
  background-color: white;
  overflow: auto;
  display: table-cell;
}
#sectionAboutMeLeft {
  padding: 30px;
  float: left;
  width: 50%;
  background-color: white;
  overflow: auto;
  display: table-cell;
}
#hAboutMe {
  text-transform: uppercase;
  font-size: 50px;
  font-weight: 600;
}
#sectionAboutMeLeft p {
  font-size: clamp(18px, 2vw, 25px);
  text-justify: distribute;
  text-align: justify;
}
span.li  {
  display: list-item; 
  margin-left: 2em
}
#sectionAboutMeRight {
  float: right;
  width: 50%;
  background-color: white;
  overflow: auto;
  display: table-cell;
}
#usrIcon {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  max-width: 150px;
  height: auto;
}
#sectionAboutMeRight #name {
  text-align: center;
  font-size: 30px;
  font-weight: bold;
}
#sectionAboutMeRight table#myInformation {
  width: 100%;
  font-size: 18px;
}
#sectionAboutMeRight table#myInformation td {
  text-align: left;
  padding: 8px;
}
@media only screen and (max-width: 850px) {
  #sectionAboutMeLeft {width: 90%; padding: 0px;}
  #sectionAboutMeRight {width: 100%;}
  #sectionAboutMeRowChild {width: 100%; padding: 0px;}
  #hAboutMe, #hCert {font-size: 20px;}
  #sectionAboutMeLeft p {font-size: 18px;}
  #sectionAboutMeRight #name {font-size: 18px;}
  #sectionAboutMeRight table#myInformation {font-size: 16px;}
  #sectionAboutMeRight table#myInformation td {padding: 2px;}
}